<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="custom-card-title">
            <i class="fas fa-palette"></i>
            <span>Temalar</span>
          </h5>
        </div>
        <div class="col-auto">
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-on:submit="submitForm"
          />
        </div>
      </div>
    </WeCard>
    <div class="theme-container">
      <a
        href="#"
        v-for="theme in themes.themes"
        :key="theme.id"
        :class="[
          'theme-card',
          selectedTheme == theme.id ? 'selected-card' : '',
        ]"
        @click.prevent="selectTheme(theme)"
      >
        <!-- <a :href="theme.demo_url" target="_blank" class="theme-card-view">
          <i class="fas fa-eye"></i>
          <span>Canlı Önizle</span>
        </a> -->
        <img :src="theme.thumbnail" class="theme-card-image" />
        <div class="theme-card-footer">
          <span>{{ theme.title }}</span>
          <button
            @click="selectTheme(theme)"
            v-if="selectedTheme === theme.id"
            class="selected-button"
          >
            Seçildi
            <i class="fas fa-check"></i>
          </button>
        </div>
      </a>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: false,
      selectedTheme: null,
    };
  },
  methods: {
    ...mapActions("themes", ["getThemes"]),
    ...mapActions("setup", ["create"]),
    selectTheme(theme) {
      this.selectedTheme = theme.id;
    },
    submitForm() {
      this.submit = true;
      this.create({
        form: {
          theme: this.selectedTheme,
        },
        onSuccess: () => {
          this.$toast.success("Temanız başarıyla değiştirildi.");
          this.submit = false;
        },
        onError: () => {
          this.$toast.error("Temanız değiştirilirken bir sorun yaşandı.");
          this.submit = false;
        },
        onFinish: () => {},
      });
    },
    getThemeData() {
      this.getThemes({
        onFinish: () => {
          this.ready = true;
          this.selectedTheme = this.themes.selectedTheme;
        },
      });
    },
  },
  computed: {
    ...mapState(["themes"]),
  },
  mounted() {
    this.getThemeData();
  },
};
</script>

<style lang="scss">
.theme-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-top: 40px;
}

.theme-card {
  display: flex;
  text-decoration: none !important;
  color: #000;
  flex-direction: column;
  box-shadow: 0 0 16px #ccc;
  border-radius: 12px;
  font-weight: bold;
  overflow: hidden;
  max-width: calc((100% / 4) - 24px);
  width: 100%;
  border: 2px solid rgba(255, 0, 0, 0);
  position: relative;
}

.theme-card img {
  width: 100%;
  max-height: 180px;
  border-radius: 12px;
  display: block;
}

.theme-card-footer span {
  font-size: 1rem;
}

.theme-card-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
}

.theme-card-footer button {
  display: flex;
  background-color: #2e9210;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.theme-card-footer button:hover {
  background-color: #36a814;
}

.theme-card-footer button:focus {
  outline: none;
}

.selected-card {
  border-color: #3953e7;
}

.selected-button {
  background-color: #3953e7 !important;
}

.selected-button > i {
  margin-left: 4px;
  font-size: 12px;
}

.theme-card-view i {
  font-size: 20px;
  margin-top: 4px;
  color: #3f51b5;
}

.theme-card-view {
  padding: 8px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: calc(50% - 50px);
  width: 100%;
  cursor: pointer;
  display: flex;
  opacity: 0;
  visibility: hidden;
  width: max-content;
  left: calc(50% - 50px);
  border-radius: 8px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.74);
  font-size: 15px;
  z-index: 2;
  text-decoration: none !important;
}

.theme-card .theme-card-view:hover {
  color: #3f51b5;
  background-color: #fff;
}

.theme-card:hover .theme-card-view {
  opacity: 1;
  visibility: visible;
}

.theme-card-image {
  transition: 2s ease-in-out all;
  width: 100%;
  height: 200px;
  max-height: 200px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 12px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.theme-card:hover .theme-card-image {
  object-position: bottom;
}

@media (max-width: 960px) {
  .theme-card {
    max-width: calc((100% / 3) - 24px);
  }
}

@media (max-width: 768px) {
  .theme-card {
    max-width: calc((100% / 2) - 24px);
  }
}
</style>